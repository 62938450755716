// @flow

import { rem } from 'polished';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  flex?: number,
  w?: number,
  h?: number,
|}>;

export default styled('div')`
  ${(p: Props) => (p.flex ? `flex: ${p.flex}` : '')};
  ${(p: Props) => (p.w ? `width: ${rem(p.w)}` : '')};
  ${(p: Props) => (p.h ? `height: ${rem(p.h)}` : '')};
`;
