// @flow
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import Hcont from './Hcont';
import MarkdownPs from './MarkdownPs';
import Section from './Section';
import Text from './Text';
import { Color, Dimen } from '../constants';
import { newsSlug } from '../util';

import type { MainNewsLink_article } from '../__generated__/MainNewsLink_article';

type Props = $ReadOnly<{|
  article: MainNewsLink_article,
|}>;

const imgWidth = 960;
const imgHeight = 660;

const MainNewsLink = ({ article }: Props) => {
  const url = article.image?.url;
  const src = url && `${url}?fit=crop&w=${imgWidth}&h=${imgHeight}`;

  return (
    <StyledSection backgroundColor={'grey'}>
      <StyledHcont>
        <StyledAreaImage>
          <img src={src} />
          <StyledDarkArea>
            <StyledText tag="div" color="white" size={32}>
              {article.headline}
            </StyledText>
          </StyledDarkArea>
        </StyledAreaImage>
        <StyledArea>
          <StyledAreaMiddle>
            <Text color="faintBlue" size={26} light>
              {article.publishDate}
            </Text>
            {article.introduction && (
              <MarkdownPs
                color="faintBlue"
                size={21}
                html={article.introduction}
              />
            )}
            <StyledLink
              to={`news/${newsSlug(article.originalId, article.headline)}`}
            >
              Read more
            </StyledLink>
          </StyledAreaMiddle>
        </StyledArea>
      </StyledHcont>
    </StyledSection>
  );
};

export default MainNewsLink;

export const fragment = graphql`
  fragment MainNewsLink_article on DatoCmsNewsArticle {
    headline
    publishDate(formatString: "D MMMM YYYY")
    introduction
    originalId
    image {
      url
    }
  }
`;

const StyledAreaImage = styled('div')`
  flex: 1;
  position: relative;
  > img {
    width: 100%;
  }
`;

const StyledDarkArea = styled('div')`
  position: absolute;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
`;

const StyledText = styled(Text)`
  text-align: center;
  text-transform: uppercase;
  margin: ${rem(Dimen.spacing / 2)};
`;

const StyledArea = styled('div')`
  flex: 1;
`;

const StyledHcont = styled(Hcont).attrs({ noPadding: true })`
  display: flex;
  align-items: flex-start;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const StyledAreaMiddle = styled('div')`
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  margin: 0 ${rem(Dimen.spacing / 2)};

  @media (max-width: 700px) {
    margin-top: ${rem(Dimen.spacing / 2)};
  }
`;

const StyledSection = styled(Section)`
  padding: ${rem(Dimen.spacing)};
`;

const StyledLink = styled(Link)`
  color: ${Color.vibrantBlue};
  text-decoration: underline;
`;
