// @flow

import { rem } from 'polished';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  color?: 'darkBlue' | 'white',
|}>;

export default styled('img').attrs({
  src: (p: Props) => `/img/logo_${p.color || 'darkBlue'}.svg`,
})`
  width: ${rem(175)};
`;
