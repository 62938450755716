// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Spacer } from '.';
import { Dimen } from '../constants';
import Button from './Button';
import Section from './Section';
import Text from './Text';
import TextHeading from './TextHeading';

export default function SectionPricingEnterprise() {
  return (
    <Section backgroundColor="darkBlue">
      <StyledWrap>
        <StyledTextWrap>
          <Text size={20} color="white">
            If you manage more than 20 units
          </Text>
          <StyledTextHeading level={3} color="white">
            GuestHug Enterprise
          </StyledTextHeading>

          <StyledDesc color="white">
            For organisations that need additional customisation, fully
            configurable settings, custom third party integrations and more.
          </StyledDesc>
          <Spacer h={Dimen.spacing} />

          <Button label="Contact Sales" href="/contact" />
        </StyledTextWrap>

        <StyledGraphic src="/img/enterprise_graphic.png" />
      </StyledWrap>
    </Section>
  );
}

const StyledWrap = styled.div`
  position: relative;
`;

const StyledTextWrap = styled.div`
  padding: ${rem(Dimen.spacing * 1.5)} 0;
  max-width: 500px;
  position: relative;
`;

const StyledGraphic = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 880px) {
    display: none;
  }
`;

const StyledTextHeading = styled(TextHeading)`
  margin: ${rem(Dimen.spacing * 0.75)} 0;
`;

const StyledDesc = styled(Text)`
  opacity: 0.7;
`;
