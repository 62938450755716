// @flow

import { rem } from 'polished';
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import Section from './Section';
import TextP from './TextP';

import type { SectionPortals_portalsPage } from '../__generated__/SectionPortals_portalsPage';

type Props = $ReadOnly<{|
  portalsPage: SectionPortals_portalsPage,
|}>;

const logoWidth = 220;
const logoHeight = 70;

const SectionPortals = ({ portalsPage }: Props) => {
  const { portals } = portalsPage;
  return (
    <Section>
      <StyledWrapper>
        <StyledFeature>
          {(portals || []).filter(Boolean).map(portal => {
            const url = portal.image?.url;
            const src =
              url && `${url}?fit=max&w=${logoWidth * 2}&h=${logoHeight * 2}`;

            return (
              <div key={portal.id}>
                <StyledImage>
                  <img src={src} />
                </StyledImage>
                <TextP small>{portal.description}</TextP>
              </div>
            );
          })}
        </StyledFeature>
      </StyledWrapper>
    </Section>
  );
};

export default SectionPortals;

export const fragment = graphql`
  fragment SectionPortals_portalsPage on DatoCmsPageFeaturesHost {
    portals {
      id
      image {
        url
      }
      description
    }
  }
`;

const StyledWrapper = styled('div')`
  text-align: center;
  margin: ${rem(Dimen.spacing * 1.5)} 0 ${rem(Dimen.spacing)};
`;

const StyledFeature = styled('div')`
  display: flex;
  justify-content: center;

  > div {
    flex: 1;
    max-width: ${rem(420)};
    margin: 0 ${rem(Dimen.spacing / 2)};

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;

    > div {
      margin-bottom: ${rem(Dimen.spacing)};
    }
  }
`;

const StyledImage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(logoHeight)};

  > img {
    width: 100%;
    display: block;
    max-width: ${rem(logoWidth)};
    max-height: ${rem(logoHeight)};
  }
`;
