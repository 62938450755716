// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Dimen, MediaQuery } from '../constants';
import { Section, Text, TextP } from '.';
import LinkedButton from './LinkedButton';
import TextHeading from './TextHeading';
import Button from './Button';

type Props = $ReadOnly<{|
  title: string,
  subtitle: ?string,
  ctaButtonLabel: ?string,
  ctaButtonLink: ?string,
  secondaryButtonLabel: ?string,
  secondaryButtonLink: ?string,
|}>;

export default function HomeHero({
  title,
  subtitle,
  ctaButtonLabel,
  ctaButtonLink,
  secondaryButtonLabel,
  secondaryButtonLink,
}: Props) {
  return (
    <Section
      backgroundColor="darkBlue"
      coverUrl="/home_hero.jpg"
      coverDarken={0.5}
    >
      <StyledWrapper>
        <StyledAreaMiddle>
          <StyledTextHeading level={1} tag="h1" invert>
            {title}
          </StyledTextHeading>
          {subtitle && <TextP color="white">{subtitle}</TextP>}
          <StyledCtaButton label={ctaButtonLabel} link={ctaButtonLink} />
          <StyledSecondaryButton
            label={secondaryButtonLabel}
            href={secondaryButtonLink}
          />
        </StyledAreaMiddle>

        <StyledScrollPrompt>
          <Text color="white" size={13} bold uppercase>
            Scroll Down
          </Text>
          <StyledArrow src="/home_scroll_arrow.svg" />
        </StyledScrollPrompt>
      </StyledWrapper>
    </Section>
  );
}

const StyledWrapper = styled('div')`
  min-height: calc(100vh - ${rem(Dimen.headerHeight)});
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    min-height: calc(100vh - ${rem(180)});
  }
`;

const StyledAreaMiddle = styled('div')`
  position: relative;
  max-width: 726px;
  text-align: center;
`;

const StyledTextHeading = styled(TextHeading)`
  margin-top: 0;
`;

const StyledCtaButton = styled(LinkedButton)`
  margin: ${rem(Dimen.spacing)};
`;

const StyledSecondaryButton = styled(Button)`
  box-sizing: border-box;
  background-color: transparent;
  border: 2px #fff solid;
  line-height: ${rem(46)};

  span {
    transition: color 0.25s;
  }

  &:hover {
    background-color: #fff;
    span {
      color: #444 !important;
    }
  }
`;

const StyledScrollPrompt = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${rem(Dimen.spacing / 2)};

  @media (max-height: 740px) {
    display: none;
  }
`;

const bounce = keyframes`
  0%, 20%, 100% { transform: translateY(${rem(-5)}); }
  10% { transform: translateY(${rem(10)}); }
`;

const StyledArrow = styled('img')`
  display: inline-block;
  margin-top: ${rem(Dimen.spacing / 2)};
  width: ${rem(20)};

  animation: ${bounce} 5s infinite;
`;
