/* eslint-disable flowtype/require-valid-file-annotation, no-undef */

exports.newsSlug = function(id, headline) {
  let headlineSlug = headline
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/ +/g, ' ');
  if (headlineSlug.length > 70) {
    headlineSlug = headlineSlug.substr(0, 60);
  }
  headlineSlug = headlineSlug.trim().replace(/ /g, '-');
  return `${id}-${headlineSlug}`;
};
