// @flow

import { Link } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color, Dimen, MediaQuery, ZIndex } from '../constants';
import Hcont from './Hcont';
import HeaderGetApp from './HeaderGetApp';

type Props = $ReadOnly<{|
  onOpenNav: () => mixed,
|}>;

const HeaderMobile = ({ onOpenNav }: Props) => (
  <>
    <StyledHeader>
      <TopHcont>
        <StyledLogoLink to="/">
          <StyledLogoGlyph />
        </StyledLogoLink>
        <StyledMenuLink onClick={() => onOpenNav()}>
          <img src="/img/header/menu.svg" style={{ width: 28, height: 28 }} />
        </StyledMenuLink>
      </TopHcont>
    </StyledHeader>
    <BottomHcont>
      <HeaderGetApp />
    </BottomHcont>
  </>
);

export default HeaderMobile;

const StyledHeader = styled('header')`
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.header};
  background-color: #fff;

  display: none;
  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    display: block;
  }
`;

const TopHcont = styled(Hcont)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${rem(Dimen.headerMobileTopHeight)};
  box-sizing: border-box;
  border-bottom: 1px ${Color.darkBlue} solid;
`;

const StyledLogoLink = styled(Link)`
  padding: ${rem(Dimen.spacing / 4)};
`;

const StyledLogoGlyph = styled('img').attrs({
  src: '/img/logo_glyph_darkBlue.svg',
})`
  width: ${rem(45)};
`;

const StyledMenuLink = styled('a')`
  height: ${rem(64)};
  width: ${rem(64)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomHcont = styled(Hcont)`
  align-items: center;
  justify-content: center;
  background-color: ${Color.darkBlue};
  color: #fff;
  height: ${rem(Dimen.headerMobileBottomHeight)};
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.49);

  display: none;
  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    display: flex;
  }
`;
