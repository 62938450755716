// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import Text from './Text';

type Props = $ReadOnly<{|
  level: 1 | 2 | 3 | 4,
  tag?: 'h1' | 'h2',
  invert?: boolean,
  className?: string,
  children: React.Node,
|}>;

const sizes = {
  '1': 70,
  '2': 46,
  '3': 46,
  '4': 30,
};

const TextHeading: React.ComponentType<Props> = styled(Text).attrs({
  tag: (p: Props) => p.tag || 'h2',
  size: (p: Props) => sizes[p.level.toString()],
  bold: (p: Props) => p.level <= 2,
  light: (p: Props) => p.level >= 3,
  color: (p: Props) => (p.invert ? 'white' : 'darkBlue'),
})`
  margin: ${rem(Dimen.spacing)} 0;
  line-height: 1;
`;

export default TextHeading;
