// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../constants';

type Props = $ReadOnly<{|
  name: string,
  label?: string,
  placeholder: string,
  multiline?: boolean,
  required?: boolean,
|}>;

const Input = ({ name, label, placeholder, multiline, required }: Props) => (
  <div>
    {label && <Label>{label}</Label>}
    <StyledInput
      name={name}
      placeholder={placeholder}
      multiline={multiline}
      tag={multiline ? 'textarea' : 'input'}
      required={required}
    />
  </div>
);

export default Input;

const Label = styled('label')`
  display: block;
`;

// eslint-disable-next-line no-unused-vars
const StyledInput = styled(({ tag = 'input', multiline, ...props }) =>
  React.createElement(tag, props)
)`
  display: block;
  font-size: ${p => rem(p.multiline ? 18 : 18)};
  border: 0;
  border-radius: ${rem(5)};
  outline: 0;
  border-bottom: 2px solid #eee;
  margin: ${rem(6)};
  padding: ${rem(6)};
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  ${p => p.multiline && ` height: ${rem(120)}`};

  &::placeholder {
    padding: ${rem(4)};
    color: ${Color.fainterBlue};
    font-size: ${p => rem(p.multiline ? 12 : 12)};
  }
`;
