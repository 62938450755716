// @flow
import { Link } from 'gatsby';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Color, Dimen, MediaQuery, ZIndex } from '../constants';
import Hcont from './Hcont';
import Section from './Section';

type Props = $ReadOnly<{|
  links: Array<{ url: string, label: string, highlight: boolean }>,
|}>;

const TabMenu = ({ links }: Props) => (
  <StyledSection backgroundColor="white">
    <StyledHcont>
      {links &&
        links.map((link, i) => (
          <StyledNavLink
            key={link.url}
            highlight={link.highlight}
            to={link.url}
            index={i}
          >
            {link.label}
          </StyledNavLink>
        ))}
    </StyledHcont>
  </StyledSection>
);

export default TabMenu;

const StyledSection = styled(Section)`
  position: fixed;
  left: 0;
  right: 0;
  z-index: ${ZIndex.header};

  top: ${rem(Dimen.headerHeight)};
  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    position: static;
    top: ${rem(Dimen.headerMobileHeight)};
  }
`;

const StyledHcont = styled(Hcont)`
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;

  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    top: ${rem(Dimen.headerMobileHeight)};
  }
`;

// eslint-disable-next-line no-unused-vars
const StyledNavLink = styled(({ highlight, ...props }) =>
  React.createElement(Link, props)
)`
  display: flex;
  padding: ${rem(Dimen.spacing / 2)} 0;
  flex: 1;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  border-width: ${rem(5)};
  border-bottom-style: solid;
  border-bottom-color: ${p =>
    p.highlight ? (p.index === 1 ? Color.red : Color.darkBlue) : 'transparent'};
`;
