// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color, Dimen } from '../constants';
import Text from './Text';

import type { TextColor } from './Text';

export type Props = $ReadOnly<{|
  small?: boolean,
  color?: TextColor,
  tag?: 'p' | 'div',
  noMargin?: boolean,
  size?: number,
  bold?: boolean,
  light?: boolean,
  children?: React.Node,
  dangerouslySetInnerHTML?: { __html: string },
|}>;

const TextP: React.ComponentType<Props> = styled(Text).attrs({
  tag: (p: Props) => p.tag || 'p',
  size: (p: Props) => (p.small ? 21 : 25),
  bold: (p: Props) => p.bold,
  light: (p: Props) => p.light || true,
  color: (p: Props) => p.color,
})`
  ${(p: Props) => !p.noMargin && `margin: ${rem(Dimen.spacing / 2)} 0`};

  a {
    color: ${Color.vibrantBlue};
    &:hover {
      transition: color 0.25s;
      color: ${Color.darkBlue};
    }
  }
`;

export default TextP;
