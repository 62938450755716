// @flow
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import Hcont from './Hcont';
import Section from './Section';
import Text from './Text';
import { Dimen } from '../constants';
import { newsSlug } from '../util';

import type { MainNewsImage_article } from '../__generated__/MainNewsImage_article';
import type { MainNewsImage_link } from '../__generated__/MainNewsImage_link';

type Props = $ReadOnly<{|
  article: MainNewsImage_article,
  prev: MainNewsImage_link | null,
  next: MainNewsImage_link | null,
|}>;

const imgWidth = 960;
const imgHeight = 400;

const MainNewsImage = ({ article, prev, next }: Props) => {
  const url = article.image?.url;
  const src = url && `${url}?fit=crop&w=${imgWidth}&h=${imgHeight}`;
  return (
    <StyledSection backgroundColor={'grey'}>
      <StyledHcont>
        <StyledAreaImage>
          <StyledAreaLinks>
            {prev && (
              <Link to={`news/${newsSlug(prev.originalId, prev.headline)}`}>
                &laquo; Previous
              </Link>
            )}
            <div />
            {next && (
              <Link to={`news/${newsSlug(next.originalId, next.headline)}`}>
                Next &raquo;
              </Link>
            )}
          </StyledAreaLinks>
          <img src={src} />
          <StyledDarkArea>
            <StyledText tag="div" color="white" size={32}>
              {article.headline}
            </StyledText>
          </StyledDarkArea>
        </StyledAreaImage>
      </StyledHcont>
    </StyledSection>
  );
};

export default MainNewsImage;

export const fragment = graphql`
  fragment MainNewsImage_article on DatoCmsNewsArticle {
    image {
      url
    }
    headline
  }
`;

export const linkFragment = graphql`
  fragment MainNewsImage_link on DatoCmsNewsArticle {
    originalId
    headline
  }
`;

const StyledAreaImage = styled('div')`
  flex: 1;
  position: relative;
  > img {
    width: 100%;
  }
`;

const StyledAreaLinks = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(20)};

  a {
    text-transform: uppercase;
    font-size: ${rem(13)};
    font-weight: bold;
    padding: ${rem(5)} 0;
  }
`;

const StyledDarkArea = styled('div')`
  position: absolute;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  margin: ${rem(Dimen.spacing / 2)};
`;

const StyledHcont = styled(Hcont).attrs({ noPadding: true })`
  display: flex;
  flex-direction: 'row';
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const StyledSection = styled(Section)`
  padding: ${rem(Dimen.spacing)} 0;
`;
