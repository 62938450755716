// @flow

import * as React from 'react';
import TextP from './TextP';

import type { Props as TextPProps } from './TextP';

type Props = $ReadOnly<{|
  html: string,
  ...TextPProps,
|}>;

const MarkdownPs = ({ html, ...rest }: Props) => (
  <TextP tag="div" dangerouslySetInnerHTML={{ __html: html }} {...rest} />
);

export default MarkdownPs;
