// @flow

import { rem } from 'polished';
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import Button from './Button';
import Hcont from './Hcont';
import TextHeading from './TextHeading';
import MarkdownPs from './MarkdownPs';
import Section from './Section';

import type { SectionScreenshot_screenshot } from '../__generated__/SectionScreenshot_screenshot';

type Props = $ReadOnly<{|
  screenshot: SectionScreenshot_screenshot,
  /**
   * Should the image come after (on the right of) the text
   */
  picAfter?: boolean,
  /**
   * If this should just show a simple phone screenshot.
   */
  imageSrc?: string,
  /**
   * If showing a phone screenshot, the illustration effect to show behind it
   * (optional).
   */
  effectSrc?: string,
  /**
   * If showing a screenshot that extends all the way to edge of screen.
   */
  fullImageSrc?: string,
  backgroundColor?: 'white' | 'grey' | 'yellow',
  backgroundImageSrc?: string,
  invert?: boolean,
|}>;

const BREAKPOINT = 860;

const SectionScreenshot = ({
  screenshot,
  picAfter,
  imageSrc,
  effectSrc,
  fullImageSrc,
  backgroundColor,
  backgroundImageSrc,
  invert,
}: Props) => {
  return (
    <StyledSection
      backgroundColor={backgroundColor}
      coverUrl={backgroundImageSrc}
    >
      {fullImageSrc && <StyledFullImage src={fullImageSrc} />}
      <StyledHcont picAfter={picAfter}>
        {effectSrc && <StyledEffect src={effectSrc} picAfter={picAfter} />}
        <StyledAreaText fullImage={fullImageSrc}>
          <TextHeading invert={invert} level={3}>
            {screenshot.heading}
          </TextHeading>
          {screenshot.content && (
            <StyledPs color={invert && 'white'} html={screenshot.content} />
          )}
          {screenshot.buttonLabel &&
            screenshot.buttonLink && (
              <Button
                label={screenshot.buttonLabel}
                href={screenshot.buttonLink}
              />
            )}
        </StyledAreaText>
        <StyledSpacer />
        <StyledAreaImage picAfter={picAfter} fullImage={fullImageSrc}>
          {imageSrc && <img src={imageSrc} />}
        </StyledAreaImage>
      </StyledHcont>
    </StyledSection>
  );
};

export default SectionScreenshot;

export const fragment = graphql`
  fragment SectionScreenshot_screenshot on DatoCmsSectionScreenshot {
    heading
    content
    buttonLabel
    buttonLink
  }
`;

const StyledSection = styled(Section)`
  overflow: hidden;
`;

const StyledFullImage = styled('img')`
  position: absolute;
  bottom: 0;
  ${p => (p.picAfter ? 'left' : 'right')}: 0;
  max-width: 55%;

  @media (max-width: ${BREAKPOINT}px) {
    max-width: 95%;
    max-height: ${rem(360)};
  }
`;

const StyledHcont = styled(Hcont)`
  display: flex;
  flex-direction: ${p => (p.picAfter ? 'row' : 'row-reverse')};
  align-items: center;
  min-height: ${rem(660)};
  position: relative;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const StyledEffect = styled('img')`
  position: absolute;
  bottom: 0;
  ${p => (p.picAfter ? 'left' : 'right')}: 50%;
  z-index: 1;

  height: ${rem(620)};
  @media (max-width: ${BREAKPOINT}px) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledAreaImage = styled('div')`
  flex: ${p => (p.fullImage ? 65 : 1)};
  align-self: flex-end;

  display: flex;
  flex-direction: ${p => (p.picAfter ? 'row' : 'row-reverse')};
  justify-content: flex-end;
  z-index: 2;

  img {
    display: block;
    max-height: ${rem(580)};
    max-width: 100%;
  }

  @media (max-width: ${BREAKPOINT}px) {
    align-self: center;
    justify-content: center;
  }
`;

const StyledAreaText = styled('div')`
  flex: ${p => (p.fullImage ? 35 : 1)};
  z-index: 2;

  margin-bottom: ${rem(Dimen.spacing)};
  @media (max-width: ${BREAKPOINT}px) {
    text-align: center;
  }
`;

const StyledSpacer = styled('div')`
  width: ${rem(Dimen.spacing)};
`;

const StyledPs = styled(MarkdownPs).attrs({ noMargin: true })`
  margin: ${rem(Dimen.spacing)} 0;
`;
