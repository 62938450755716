// @flow

import { darken, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../constants';

type Props = $ReadOnly<{
  label: string,
}>;

const SubmitButton = ({ label, ...rest }: Props) => (
  <StyledWrapper {...rest}>
    <span>{label}</span>
  </StyledWrapper>
);

export default SubmitButton;

const StyledWrapper = styled('button')`
  border: 0;
  cursor: pointer;
  background-color: ${Color.vibrantBlue};
  padding: ${rem(10)};
  margin-left: ${rem(6)};
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 5px;
  transition: background-color 0.4s;
  span {
    display: block;
    color: #fff;
    font-size: ${rem(14)};
    text-transform: uppercase;
  }
  &:hover {
    background-color: ${darken(0.05, Color.vibrantBlue)}; // #1295c0;
  }
`;
