// @flow

import { rem } from 'polished';
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Text } from '.';
import { Dimen } from '../constants';

import type { SectionTestimonialSquare_testimonial } from '../__generated__/SectionTestimonialSquare_testimonial';

type Props = $ReadOnly<{|
  testimonial: SectionTestimonialSquare_testimonial,
  backgroundColor: 'red' | 'darkBlue',
|}>;

const SectionTestimonialSquare = ({ testimonial }: Props) => {
  const s = Dimen.spacing;
  const url = testimonial.photo?.url;
  const backgroundUrl = testimonial.background?.url;
  const src = url && `${url}?fit=crop&w=${s * 2}&h=${s * 2}`;
  return (
    <StyledAreaImage>
      {testimonial.background?.url && (
        <img src={backgroundUrl} style={{ width: '100%' }} />
      )}
      <StyledWrapper>
        <img src="/stars.png" />
        <Text size={46} light italic color="white">
          &quot;
          {testimonial.quote}
          &quot;
        </Text>
        <StyledBottom>
          {testimonial.photo?.url && <StyledPhoto src={src} />}
          <Text light size={17} color="white" uppercase>
            {[testimonial.name, testimonial.role].join(', ')}
          </Text>
        </StyledBottom>
      </StyledWrapper>
    </StyledAreaImage>
  );
};

export default SectionTestimonialSquare;

export const fragment = graphql`
  fragment SectionTestimonialSquare_testimonial on DatoCmsTestimonial {
    quote
    name
    role
    photo {
      url
    }
    background {
      url
    }
  }
`;

const StyledAreaImage = styled('div')`
  flex: 1;
  position: relative;
`;

const StyledWrapper = styled('div')`
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${rem(Dimen.spacing * 1.5)} ${rem(Dimen.spacing)};
`;

const StyledBottom = styled('div')`
  display: flex;
  align-items: center;
  margin-top: ${rem(Dimen.spacing / 2)};
`;

const StyledPhoto = styled('img')`
  margin-right: ${rem(Dimen.spacing / 4)};
  width: ${rem(Dimen.spacing)};
  height: ${rem(Dimen.spacing)};
  border-radius: 50%;
  border: 2px #fff solid;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
`;
