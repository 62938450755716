// @flow

import { rem } from 'polished';
import styled from 'styled-components';
import { Dimen } from '../constants';

type Props = $ReadOnly<{|
  noPadding?: boolean,
|}>;

export default styled('div')`
  display: block;
  box-sizing: border-box;
  max-width: ${rem(1170)};
  margin: 0 auto;
  padding: 0 ${(p: Props) => (p.noPadding ? 0 : rem(Dimen.spacing / 2))};
`;
