// @flow

import { Link } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import config from '../config';
import { Color, Dimen, MediaQuery, ZIndex } from '../constants';
import Hcont from './Hcont';
import HeaderGetApp from './HeaderGetApp';
import Logo from './Logo';

const Header = () => (
  <StyledHeader>
    <StyledHcont>
      <StyledLogoLink to="/">
        <Logo />
      </StyledLogoLink>
      <StyledNav>
        <StyledNavLink to="/about">About Us</StyledNavLink>
        <StyledNavLink to="/host">Features</StyledNavLink>
        <StyledNavLink to="/faq/host">FAQ</StyledNavLink>
        <StyledNavLink to="/pricing">Pricing</StyledNavLink>
        <StyledNavLink
          target="_blank"
          rel="noopener noreferrer"
          href={config.PORTAL_ROOT}
        >
          Sign In
        </StyledNavLink>
      </StyledNav>
      <StyledHeaderGetApp />
    </StyledHcont>
  </StyledHeader>
);

export default Header;

const StyledHeader = styled('header')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.header};
  background-color: #fff;

  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    display: none;
  }
`;

const StyledHcont = styled(Hcont)`
  height: ${rem(Dimen.headerHeight)};
  display: flex;
  align-items: center;
`;

const StyledLogoLink = styled(Link)`
  padding: ${rem(Dimen.spacing / 4)};
  padding-bottom: ${rem(Dimen.spacing / 8)};
  border-radius: 4px;
  transition: background-color 0.25s;

  &:hover {
    background-color: ${Color.hoverBg};
  }
`;

const StyledNav = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StyledNavLink = styled(
  props =>
    props.to
      ? React.createElement(Link, props)
      : props.href
        ? React.createElement('a', props)
        : React.createElement('span', props)
)`
  font-weight: bold;
  padding: ${rem(Dimen.spacing / 4)};
  margin: 0 ${rem(Dimen.spacing / 4)};
  border-radius: 4px;
  transition: background-color 0.25s;

  ${p => (p.to || p.href) && `&:hover { background-color: ${Color.hoverBg}; }`};
`;

const StyledHeaderGetApp = styled(HeaderGetApp)`
  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    display: none;
  }
`;
