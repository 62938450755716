// @flow

import { transparentize } from 'polished';

const palette = {
  // Standard strong palette colors that never change
  darkBlue: '#253745',
  darkerBlue: '#1A2833',
  faintBlue: '#6D89AE',
  fainterBlue: '#AFBED3',
  red: '#ee4641',
  darkRed: '#DB3F3B',
  vibrantBlue: '#3389ff',
  white: '#ffffff',
  yellow: '#fed27a',
};

export default {
  ...palette,

  hoverBg: transparentize(0.8, palette.fainterBlue),
};
