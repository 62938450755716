// @flow

import { Link } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import config from '../config';
import { Color, Dimen, ZIndex } from '../constants';
import Hcont from './Hcont';

type Props = $ReadOnly<{|
  open: boolean,
  onOpenChange: boolean => mixed,
|}>;

const NavMobile = ({ open, onOpenChange }: Props) => (
  <StyledContainer open={open}>
    <TopHcont>
      <StyledLogoLink to="/">
        <StyledLogoGlyph />
      </StyledLogoLink>
      <StyledCloseLink onClick={() => onOpenChange(!open)}>X</StyledCloseLink>
    </TopHcont>
    <Hcont>
      <StyledNav>
        <StyledNavLink to="/about">About Us</StyledNavLink>
        <StyledNavLink to="/host">Features</StyledNavLink>
        <StyledNavLink to="/faq/host">FAQ</StyledNavLink>
        <StyledNavLink to="/pricing">Pricing</StyledNavLink>
        <StyledNavLink
          target="_blank"
          rel="noopener noreferrer"
          href={config.PORTAL_ROOT}
        >
          Sign In
        </StyledNavLink>
        <StyledNavLink to="/partners">Partners</StyledNavLink>
        <StyledNavLink to="/news/host">News</StyledNavLink>
        <StyledNavLink to="/contact">Contact us</StyledNavLink>
      </StyledNav>
    </Hcont>
  </StyledContainer>
);

export default NavMobile;

const StyledContainer = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: ${Color.darkBlue};
  z-index: ${ZIndex.navMobile};
  transition: left 0.2s;

  ${p =>
    p.open &&
    `
    left: 0;
  `};
`;

const TopHcont = styled(Hcont)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${rem(Dimen.headerMobileTopHeight)};
  box-sizing: border-box;
`;

const StyledLogoLink = styled(Link)`
  padding: ${rem(Dimen.spacing / 4)};
`;

const StyledLogoGlyph = styled('img').attrs({
  src: '/img/logo_glyph_white.svg',
})`
  width: ${rem(45)};
`;

const StyledCloseLink = styled('a')`
  height: ${rem(64)};
  width: ${rem(64)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: ${rem(40)};
  user-select: none;
`;

const StyledNav = styled('div')`
  margin: ${rem(Dimen.spacing)} ${rem(Dimen.spacing * 2)};
`;

const StyledNavLink = styled(
  props =>
    props.to
      ? React.createElement(Link, props)
      : props.href
        ? React.createElement('a', props)
        : React.createElement('span', props)
)`
  display: block;
  height: ${rem(68)};
  line-height: ${rem(68)};
  font-size: ${rem(35)};
  font-weight: 300;
  color: #fff;
`;
