// @flow

import * as React from 'react';
import styled from 'styled-components';
import { Domain } from '../constants';
import AppPlatformIcon from './AppPlatformIcon';
import Text from './Text';

type Props = $ReadOnly<{|
  className?: string,
|}>;

const HeaderGetApp = ({ className }: Props) => (
  <StyledContainer className={className}>
    <StyledSpan>GET THE APP</StyledSpan>
    <a href={Domain.appLinkIos} target="_blank" rel="noopener noreferrer">
      <AppPlatformIcon platform="apple" />
    </a>
    <a href={Domain.appLinkAndroid} target="_blank" rel="noopener noreferrer">
      <AppPlatformIcon platform="android" />
    </a>
  </StyledContainer>
);

export default HeaderGetApp;

const StyledContainer = styled(Text).attrs({
  tag: 'div',
  bold: true,
})`
  display: flex;
  align-items: center;

  a {
    transition: opacity 0.25s;
    &:hover {
      opacity: 0.85;
    }
  }
`;

const StyledSpan = styled('span')`
  display: inline-block;
  margin-right: 20px;
`;
