// @flow

import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled, { injectGlobal } from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { Dimen, MediaQuery } from '../constants';
import Footer from './Footer';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import NavMobile from './NavMobile';

injectGlobal`
  html, body {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 100%;
    @media (max-width: 1240px) { font-size: 85%; }
    @media (max-width: 800px) { font-size: 75%; }
    @media (max-width: 520px) { font-size: 70%; }
    @media (max-width: 360px) { font-size: 65%; }
  }

  body, input, button, select, textarea {
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #233746;
    line-height: 1.3;
    font-size: ${rem(17)};
    font-weight: 400;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
  }
`;

type Props = $ReadOnly<{|
  noHeaderSpacing?: boolean,
  children: React.Node,
|}>;

type State = {|
  mobileNavOpen: boolean,
|};

export default class Layout extends React.PureComponent<Props, State> {
  state = {
    mobileNavOpen: false,
  };

  render() {
    const { noHeaderSpacing, children } = this.props;
    const { mobileNavOpen } = this.state;

    return (
      <>
        <Helmet
          titleTemplate="%s | GuestHug"
          style={[
            mobileNavOpen
              ? {
                  type: 'text/css',
                  cssText: 'body { overflow: hidden }',
                }
              : {},
          ]}
        >
          <link
            href="https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700"
            rel="stylesheet"
          />
          {[196, 64, 32, 16].map(size => (
            <link
              key={size}
              rel="icon"
              type="image/png"
              sizes={`${size}x${size}`}
              href={`/img/favicon/favicon-${size}.png`}
            />
          ))}
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          {/* Google Tag Manager */}
          <script
          >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-54Z77G9');`}</script>
        </Helmet>
        {/* Google Tag Manager */}
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-54Z77G9"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />

        <Header />
        <HeaderMobile
          onOpenNav={() => this.setState({ mobileNavOpen: true })}
        />
        <NavMobile
          open={mobileNavOpen}
          onOpenChange={value => this.setState({ mobileNavOpen: value })}
        />
        <StyledContent noHeaderSpacing={noHeaderSpacing}>
          {children}
        </StyledContent>
        <Footer />
        <CookieConsent
          buttonStyle={{
            borderRadius: `${rem(25)}`,
          }}
          buttonText="I Understand"
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </>
    );
  }
}

const StyledContent = styled('div')`
  padding-top: ${p => (p.noHeaderSpacing ? 0 : rem(Dimen.headerHeight))};
  @media (max-width: ${MediaQuery.headerMobileMaxWidth}px) {
    padding-top: 0;
  }
`;
