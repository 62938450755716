// @flow

import { graphql, Link } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color, Dimen } from '../constants';
import { newsSlug } from '../util';
import Text from './Text';

import type { NewsLink_article } from '../__generated__/NewsLink_article';

type Props = $ReadOnly<{|
  article: NewsLink_article,
|}>;

const imgWidth = 400;
const imgHeight = 300;

const NewsLink = ({ article }: Props) => {
  const url = article.image?.url;
  const src = url && `${url}?fit=crop&w=${imgWidth}&h=${imgHeight}`;

  return (
    <StyledContainer key={article.originalId}>
      <StyledImage>
        <img src={src} />
      </StyledImage>
      <StyledHeadline>
        <Text small>{article.headline}</Text>
      </StyledHeadline>
      <StyledLink to={`news/${newsSlug(article.originalId, article.headline)}`}>
        Read more
      </StyledLink>
    </StyledContainer>
  );
};

export default NewsLink;

export const fragment = graphql`
  fragment NewsLink_article on DatoCmsNewsArticle {
    image {
      url
    }
    originalId
    headline
  }
`;

const StyledContainer = styled('div')`
  display: inline-block;
  vertical-align: top;
  width: ${rem(Dimen.spacing * 6)};
  margin: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing / 2)};
`;

const StyledImage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    display: block;
    max-width: ${rem(imgWidth)};
    max-height: ${rem(imgHeight)};
  }
`;

const StyledHeadline = styled('p')`
  text-transform: uppercase;
  margin: ${rem(Dimen.spacing / 2)} 0;
`;

const StyledLink = styled(Link)`
  color: ${Color.vibrantBlue};
  text-decoration: underline;
`;
