// @flow

import { graphql } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import { unwrapConnection } from '../util';
import Button from './Button';
import Section from './Section';
import SectionQuickPrompt from './SectionQuickPrompt';
import TextHeading from './TextHeading';
import TextP from './TextP';

import type { SectionsPartners_partnersConnection } from '../__generated__/SectionsPartners_partnersConnection';

type Props = $ReadOnly<{|
  partnersConnection: SectionsPartners_partnersConnection,
|}>;

const logoWidth = 260;
const logoHeight = 80;

const SectionsPartners = ({ partnersConnection }: Props) => {
  const partners = unwrapConnection(partnersConnection);
  return (
    <>
      <Section>
        <StyledWrapper>
          <TextHeading level={3}>Our Partners</TextHeading>
          <StyledPartners>
            {partners.map(partner => {
              const url = partner.logo?.url;
              const src =
                url && `${url}?fit=max&w=${logoWidth * 2}&h=${logoHeight * 2}`;

              return (
                <div key={partner.id}>
                  <StyledLogo>
                    <img src={src} alt={partner.name} />
                  </StyledLogo>
                  <TextP small>{partner.description}</TextP>
                </div>
              );
            })}
          </StyledPartners>
        </StyledWrapper>
      </Section>
      <SectionQuickPrompt
        text="Find out how to become a Partner"
        button={<Button label="View now" href="/partners" />}
      />
    </>
  );
};

export default SectionsPartners;

export const fragment = graphql`
  fragment SectionsPartners_partnersConnection on DatoCmsPartnerConnection {
    edges {
      node {
        id
        name
        logo {
          url
        }
        description
      }
    }
  }
`;

const StyledWrapper = styled('div')`
  text-align: center;
  margin: ${rem(Dimen.spacing * 1.5)} 0 ${rem(Dimen.spacing)};
`;

const StyledPartners = styled('div')`
  display: flex;
  justify-content: center;

  > div {
    flex: 1;
    max-width: 260px;
    margin: 0 ${rem(Dimen.spacing / 2)};

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 750px) {
    flex-direction: column;
    > div {
      margin: ${rem(Dimen.spacing / 2)} auto !important;
    }
  }
`;

const StyledLogo = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(logoHeight)};

  > img {
    display: block;
    width: 100%;
    max-width: ${rem(logoWidth)};
    max-height: ${rem(logoHeight)};
  }
`;
