// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color, Dimen } from '../constants';
import Text from './Text';
import TextHeading from './TextHeading';
import Section from './Section';
import Spacer from './Spacer';

type Props = {
  plans: Array<{ name: string, prices: Array<[number, number]> | null }>,
};

type State = {|
  plan: string,
  quantity: number,
|};

export default class SectionPricingSlider extends React.PureComponent<
  Props,
  State
> {
  state = {
    plan: 'Premium',
    quantity: 1,
  };

  render() {
    const { plans } = this.props;
    const { plan, quantity } = this.state;

    const selectedPlan = plans.find(p => p.name === plan);

    let priceText = 'Free';
    let savingsText = '';
    if (selectedPlan && selectedPlan.prices) {
      let cumulativePrice = 0;
      let cumulativeSavings = 0;
      const basePrice = selectedPlan.prices[0][1];
      for (let i = 1; i <= quantity; i++) {
        const item = [...selectedPlan.prices]
          .reverse()
          .find(([minProperties]) => minProperties <= i);
        if (!item) throw new Error(`Price not found for ${plan}/${quantity}`);
        const itemPrice = item[1];
        cumulativePrice += itemPrice;
        cumulativeSavings += basePrice - itemPrice;
      }
      priceText = `£${(cumulativePrice / 100).toFixed(2)} per month`;
      if (cumulativeSavings > 0) {
        savingsText = `Save £${(cumulativeSavings / 100).toFixed(2)}`;
      }
    }

    return (
      <Section backgroundColor="grey">
        <StyledWrapper>
          <TextHeading level={3}>Calculate your cost</TextHeading>

          <StyledPlans>
            {plans.map(p => (
              <div key={p.name}>
                <input
                  id={p.name}
                  type="radio"
                  value={p.name}
                  checked={plan === p.name}
                  onChange={ev => this.setState({ plan: ev.target.value })}
                />
                <label htmlFor={p.name}>
                  <Text
                    size={18}
                    color={plan === p.name ? 'darkBlue' : 'faintBlue'}
                  >
                    {p.name}
                  </Text>
                </label>
              </div>
            ))}
          </StyledPlans>
          <Spacer h={Dimen.spacing * 0.5} />

          <StyledSlider
            type="range"
            min="1"
            max="20"
            value={quantity}
            onChange={ev => this.setState({ quantity: ev.target.value })}
          />

          <Spacer h={Dimen.spacing * 0.4} />
          <StyledBottomRow>
            <Text size={18}>
              {quantity} propert
              {quantity == 1 ? 'y' : 'ies'}
            </Text>

            <div className="right">
              <Text size={25}>{priceText}</Text>
              <Text size={18} color="vibrantBlue">
                {savingsText}
              </Text>
            </div>
          </StyledBottomRow>
        </StyledWrapper>
      </Section>
    );
  }
}

const StyledWrapper = styled('div')`
  margin: ${rem(Dimen.spacing * 1.25)} 0 ${rem(Dimen.spacing)};
`;

const StyledPlans = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    position: relative;
  }

  input {
    display: none;
  }

  label {
    display: block;
    padding: 5px 0 5px 40px;
    margin: 0 35px 15px 0;
    cursor: pointer;
  }

  label::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border: 2px #d5d5d5 solid;
    border-radius: 50%;
    transition: border-color 0.25s;
  }

  label::after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: background-color 0.25s;
  }

  input:checked + label::before {
    border-color: ${Color.vibrantBlue};
  }

  input:checked + label::after {
    background: ${Color.vibrantBlue};
  }
`;

const StyledSlider = styled.input`
  appearance: none;
  width: 100%;
  height: 8px;
  background-color: #eaeaea;
  border: 1px #e3e3e3 solid;
  border-radius: 4px;
  outline: none;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 28px;
    height: 28px;
    background: ${Color.vibrantBlue};
    border: 0;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 28px;
    height: 28px;
    background: ${Color.vibrantBlue};
    border: 0;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const StyledBottomRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 60px;

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span:first-child {
      margin-bottom: 5px;
    }
  }
`;
