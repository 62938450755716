// @flow

import { rem } from 'polished';
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import MarkdownPs from './MarkdownPs';
import Text from './Text';

import type { QuestionAnswer_faq } from '../__generated__/QuestionAnswer_faq';

type Props = $ReadOnly<{|
  faq: QuestionAnswer_faq,
|}>;

type State = {|
  open: boolean,
|};

export default class QuestionAnswer extends React.PureComponent<Props, State> {
  state = {
    open: false,
  };

  render() {
    const { faq } = this.props;
    const { open } = this.state;

    if (!faq.answer) return null;

    return (
      <Wrapper>
        <Block>
          <Question
            onClick={() =>
              this.setState(prevState => ({
                open: !prevState.open,
              }))
            }
          >
            <Text size={25}>{faq.question}</Text>
            {open ? <img src="/img/open.png" /> : <img src="/img/close.png" />}
          </Question>
          {open && (
            <Answer>
              <MarkdownPs color="faintBlue" size={21} html={faq.answer} />
            </Answer>
          )}
        </Block>
      </Wrapper>
    );
  }
}

export const fragment = graphql`
  fragment QuestionAnswer_faq on DatoCmsFaq {
    question
    answer
  }
`;

const Wrapper = styled('div')`
  width: 100%;
`;

const Block = styled('div')`
  padding: ${rem(Dimen.spacing / 4)} 0;
`;

const Question = styled('div')`
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: ${rem(Dimen.spacing / 4)} 0;

  > ${Text} {
    flex: 1;
  }

  > img {
    float: right;
    width: ${rem(Dimen.spacing * 0.8)};
    padding-top: ${rem(Dimen.spacing / 4)};
  }
`;

const Answer = styled('div')``;
