// @flow

import { rem } from 'polished';
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Section, Text } from '.';
import { Dimen } from '../constants';

import type { SectionTestimonial_testimonial } from '../__generated__/SectionTestimonial_testimonial';

type Props = $ReadOnly<{|
  testimonial: SectionTestimonial_testimonial,
  backgroundColor: 'red' | 'darkBlue',
|}>;

const SectionTestimonial = ({ testimonial, backgroundColor }: Props) => {
  const s = Dimen.spacing;
  const url = testimonial.photo?.url;
  const src = url && `${url}?fit=crop&w=${s * 2}&h=${s * 2}`;

  return (
    <Section backgroundColor={backgroundColor}>
      <StyledWrapper>
        <Text size={46} light italic color="white">
          &quot;
          {testimonial.quote}
          &quot;
        </Text>
        <StyledBottom>
          {testimonial.photo?.url && <StyledPhoto src={src} />}
          <Text light size={17} color="white" uppercase>
            {[testimonial.name, testimonial.role].join(', ')}
          </Text>
        </StyledBottom>
      </StyledWrapper>
    </Section>
  );
};

export default SectionTestimonial;

export const fragment = graphql`
  fragment SectionTestimonial_testimonial on DatoCmsTestimonial {
    quote
    name
    role
    photo {
      url
    }
  }
`;

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${rem(Dimen.spacing * 1.5)} 0;
`;

const StyledBottom = styled('div')`
  display: flex;
  align-items: center;
  margin-top: ${rem(Dimen.spacing / 2)};
`;

const StyledPhoto = styled('img')`
  margin-right: ${rem(Dimen.spacing / 4)};
  width: ${rem(Dimen.spacing)};
  height: ${rem(Dimen.spacing)};
  border-radius: 50%;
  border: 2px #fff solid;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
`;
