// @flow
import { rem } from 'polished';
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import Section from './Section';
import TextP from './TextP';

import type { SectionPricingFeatures_pricing } from '../__generated__/SectionPricingFeatures_pricing';

type Props = $ReadOnly<{|
  pricing: SectionPricingFeatures_pricing,
|}>;

const logoWidth = 220;
const logoHeight = 70;

const SectionPricingFeatures = ({ pricing }: Props) => {
  const { pricingFeatures } = pricing;
  return (
    <Section backgroundColor="grey">
      <StyledWrapper>
        <StyledFeature>
          {(pricingFeatures || []).filter(Boolean).map(pricingFeature => {
            const url = pricingFeature.image?.url;
            const src =
              url && `${url}?fit=max&w=${logoWidth * 2}&h=${logoHeight * 2}`;

            return (
              <div key={pricingFeature.id}>
                <StyledImage>
                  <img src={src} />
                </StyledImage>
                <TextP small>{pricingFeature.heading}</TextP>
              </div>
            );
          })}
        </StyledFeature>
      </StyledWrapper>
    </Section>
  );
};

export default SectionPricingFeatures;

export const fragment = graphql`
  fragment SectionPricingFeatures_pricing on DatoCmsPagePricing {
    pricingFeatures {
      id
      image {
        url
      }
      heading
    }
  }
`;

const StyledWrapper = styled('div')`
  text-align: center;
  margin: ${rem(Dimen.spacing * 1.5)} 0 ${rem(Dimen.spacing)};
`;

const StyledFeature = styled('div')`
  display: flex;
  justify-content: center;

  > div {
    flex: 1;
    max-width: ${rem(Dimen.spacing * 4)};
    margin: 0 ${rem(Dimen.spacing / 2)};

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: center;
    > div {
      margin-bottom: ${rem(Dimen.spacing)};
    }
  }
`;

const StyledImage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(logoHeight)};

  > img {
    display: block;
    max-width: ${rem(logoWidth)};
    max-height: ${rem(logoHeight)};
  }
`;
