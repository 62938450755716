// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color, Dimen } from '../constants';

type Props = $ReadOnly<{|
  onDismiss: () => mixed,
  title: string,
  padded?: boolean,
  children: React.Node,
|}>;

const Modal = ({ onDismiss, title, padded, children }: Props) => (
  <StyledMask onClick={onDismiss}>
    <StyledModal onClick={e => e.stopPropagation()}>
      <StyledTopRow>
        <span>{title}</span>
        <a onClick={onDismiss}>X</a>
      </StyledTopRow>
      <StyledChildren padded={padded}>{children}</StyledChildren>
    </StyledModal>
  </StyledMask>
);

export default Modal;

const StyledMask = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  z-index: 100;
`;

const StyledModal = styled('div')`
  margin: auto;
  background-color: #fff;
  width: 100%;
  max-width: ${rem(400)};
  max-height: ${rem(500)};
  display: flex;
  flex-direction: column;
`;

const StyledTopRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing / 2)}
    ${rem(Dimen.spacing / 4)};
  border-bottom: 1px #e7ebf1 solid;

  span {
    font-size: ${rem(20)};
  }

  a {
    font-size: ${rem(25)};
    &:hover {
      color: ${Color.vibrantBlue};
    }
  }
`;

const StyledChildren = styled('div')`
  flex: 1;
  ${p => p.padded && `padding: ${rem(Dimen.spacing / 2)}`};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
