// @flow

import styled from 'styled-components';

type Props = $ReadOnly<{|
  platform: 'android' | 'apple',
  large?: boolean,
|}>;

export default styled('img').attrs({
  src: (p: Props) => `/img/header/${p.platform || 'android'}.svg`,
})`
  width: ${p => (p.large ? 50 : 25)}px;
  display: inline-block;
  margin: 6px;
`;
