// @flow

import { Link } from 'gatsby';
import { lighten, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import config from '../config';
import { Color, Dimen } from '../constants';
import Hcont from './Hcont';
import Logo from './Logo';
import Text from './Text';

const Footer = () => (
  <StyledFooter>
    <StyledHcont>
      <StyledTop>
        <StyledAreaLogo>
          <Link to="/">
            <Logo color="white" />
          </Link>
        </StyledAreaLogo>
        <StyledAllNav>
          <StyledNav>
            <StyledTextTitle tag="div" size={21}>
              Product
            </StyledTextTitle>
            <StyledNavLink to="/host">Host</StyledNavLink>
            <StyledNavLink to="/guest">Guest</StyledNavLink>
            <StyledNavLink to="/pricing">Pricing</StyledNavLink>
            <StyledNavLink to="/faq/host">FAQ</StyledNavLink>
            <StyledNavLink
              target="_blank"
              rel="noopener noreferrer"
              href={config.PORTAL_ROOT}
            >
              Sign In
            </StyledNavLink>
          </StyledNav>
          <StyledNav>
            <StyledTextTitle tag="div" size={21}>
              Company
            </StyledTextTitle>
            <StyledNavLink to="/about">About us</StyledNavLink>
            <StyledNavLink to="/partners">Partners</StyledNavLink>
            <StyledNavLink to="/news/host">News</StyledNavLink>
            <StyledNavLink to="/privacy">Privacy</StyledNavLink>
            <StyledNavLink to="/terms">Terms of Service</StyledNavLink>
          </StyledNav>
          <StyledNav>
            <StyledTextTitle tag="div" size={21}>
              Get in Touch
            </StyledTextTitle>
            <StyledNavLink to="/contact">Contact us</StyledNavLink>
          </StyledNav>
        </StyledAllNav>
        <StyledRight>
          <StyledSocialLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/guesthug/"
          >
            <img src="/img/footer/instagram.png" />
          </StyledSocialLink>
          <StyledSocialLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/guesthug?lang=en"
          >
            <img src="/img/footer/twitter.png" />
          </StyledSocialLink>
          <StyledSocialLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/Guesthug/"
          >
            <img src="/img/footer/facebook.png" />
          </StyledSocialLink>
        </StyledRight>
      </StyledTop>
      <StyledTextBottom tag="div">
        <span>© {new Date().getFullYear()} GuestHug. All Rights Reserved.</span>
        <StyledDevBy>
          Developed by{' '}
          <a
            href="https://www.m10c.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            M10c
          </a>
          .
        </StyledDevBy>
      </StyledTextBottom>
    </StyledHcont>
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled('footer')`
  background-color: ${Color.darkerBlue};
`;

const StyledHcont = styled(Hcont)`
  padding-top: ${rem(Dimen.spacing)};
  padding-bottom: ${rem(Dimen.spacing)};
`;

const StyledTop = styled('div')`
  display: flex;
  flex-direction: row;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const StyledTextTitle = styled(Text)`
  padding: ${rem(Dimen.spacing / 4)} 0;
`;

const StyledAreaLogo = styled('div')`
  flex: 2;
  margin-bottom: ${rem(Dimen.spacing / 2)};

  > a {
    padding: ${rem(Dimen.spacing / 4)} 0;
    transition: opacity 0.25s;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const StyledAllNav = styled('div')`
  flex: 3;
  display: flex;
  flex-direction: row;
`;

const StyledNav = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${Color.white};
  margin-bottom: ${rem(Dimen.spacing / 2)};
`;

const StyledNavLink = styled(
  props =>
    props.to
      ? React.createElement(Link, props)
      : props.href
        ? React.createElement('a', props)
        : React.createElement('div', props)
)`
  display: block;
  padding: ${rem(Dimen.spacing / 8)} 0;
  transition: color 0.25s;

  ${p => p.to && `&:hover { color: ${Color.faintBlue}; }`};
`;

const StyledRight = styled(Text).attrs({
  tag: 'div',
  bold: true,
})`
  display: flex;
  flex: 2;
  align-items: flex-start;
  justify-content: flex-end;
`;

const StyledTextBottom = styled(Text)`
  padding: ${rem(Dimen.spacing)} 0;
  display: flex;
  justify-content: space-between;
  color: ${Color.white};
`;

const StyledDevBy = styled('span')`
  color: #4a5f6f;
  a {
    color: ${lighten(0.1, '#4a5f6f')};
    transition color 0.25s;
    &:hover { color: #fff }
  }
`;

const StyledSocialLink = styled('a')`
  transition: opacity 0.25s;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 25px;
    display: inline-block;
    margin: 10px;
  }
`;
