// @flow

import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../constants';
import Hcont from './Hcont';

const colors = {
  white: '#fff',
  grey: '#f2f2f2',
  red: '#fa4238',
  darkBlue: Color.darkBlue,
  yellow: Color.yellow,
  lightGreen: '#8fd0b8',
};

type Props = $ReadOnly<{|
  backgroundColor?: $Keys<typeof colors>,
  coverUrl?: string,
  coverDarken?: number,
  children?: React.Node,
  className?: string,
|}>;

const Section = ({
  backgroundColor,
  coverUrl,
  coverDarken,
  children,
  className,
}: Props) => (
  <Wrapper
    backgroundColor={backgroundColor}
    coverUrl={coverUrl}
    className={className}
  >
    {coverDarken && <Mask />}
    <Hcont>{children}</Hcont>
  </Wrapper>
);

export default Section;

const Wrapper = styled('section')`
  overflow: auto;
  position: relative;
  background-color: ${(p: Props) => colors[p.backgroundColor || 'white']};
  ${(p: Props) =>
    p.coverUrl ? `background: url(${p.coverUrl}) center bottom` : ''};
  background-size: cover;
`;

const Mask = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(35, 55, 70, 0.4);
`;
