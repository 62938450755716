// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../constants';

import type { ReactComponentStyled } from 'styled-components';

export type TextColor =
  | 'darkBlue'
  | 'darkerBlue'
  | 'faintBlue'
  | 'fainterBlue'
  | 'white';

type Props = $ReadOnly<{|
  tag?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'div',
  color?: TextColor,
  size?: number,
  bold?: boolean,
  light?: boolean,
  italic?: boolean,
  uppercase?: boolean,
  children: React.Node,
  className?: string,
  dangerouslySetInnerHTML?: { __html: string },
|}>;

/**
 * Make sure all of these CSS rules cascade, as this could either be a text
 * element (em, p, etc.), or a wrapper around text elements.
 */
const Text: ReactComponentStyled<Props> = styled(
  ({ tag = 'span', children, className, dangerouslySetInnerHTML }: Props) =>
    // eslint-disable-next-line react/no-danger-with-children
    React.createElement(tag, { className, dangerouslySetInnerHTML }, children)
)`
  ${(p: Props) => (p.color ? `color: ${Color[p.color]}` : '')};
  ${(p: Props) => (p.uppercase ? 'text-transform: uppercase' : '')};
  ${(p: Props) => (p.light ? 'font-weight: 300' : '')};
  ${(p: Props) => (p.bold ? 'font-weight: 700' : '')};
  ${(p: Props) => (p.size ? `font-size: ${rem(p.size)}` : '')};
  ${(p: Props) => (p.italic ? `font-style: italic;` : '')};

  strong {
    font-weight: 700;
  }
`;

export default Text;
