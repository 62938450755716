// @flow

import { Link } from 'gatsby';
import { lighten, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color, Dimen } from '../constants';
import Text from './Text';

type Props = $ReadOnly<{|
  label: string,
  to?: string,
  href?: string,
  onClick?: () => mixed,
  className?: string,
|}>;

const Button = ({ label, ...rest }: Props) => (
  <StyledButton {...rest}>
    <Text color="white" bold uppercase>
      {label}
    </Text>
  </StyledButton>
);

const StyledButton = styled(
  (props: Props) =>
    props.to
      ? // $FlowFixMe
        React.createElement(Link, props)
      : React.createElement('a', props)
)`
  display: inline-block;
  height: ${rem(50)};
  line-height: ${rem(50)};
  text-align: center;
  border-radius: ${rem(25)};
  background-color: ${Color.vibrantBlue};
  padding: 0 ${rem(Dimen.spacing)};
  cursor: pointer;
  transition: background-color 0.25s;
  white-space: nowrap;

  &:hover {
    background-color: ${lighten(0.05, Color.vibrantBlue)};
  }
`;

export default Button;
