// @flow

import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import Section from './Section';
import Spacer from './Spacer';
import TextHeading from './TextHeading';

type Props = $ReadOnly<{|
  text: string,
  button: React.Node,
  backgroundColor?: 'darkBlue' | 'lightGreen',
|}>;

const SectionQuickPrompt = ({ text, button, backgroundColor }: Props) => (
  <Section backgroundColor={backgroundColor ? backgroundColor : 'darkBlue'}>
    <StyledWrapper>
      <StyledHeading level={3} invert>
        {text}
      </StyledHeading>
      <Spacer w={Dimen.spacing} />
      {button}
    </StyledWrapper>
  </Section>
);

export default SectionQuickPrompt;

const StyledWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHeading = styled(TextHeading)`
  flex: 1;
`;
