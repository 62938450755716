// @flow
import { rem } from 'polished';
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import MarkdownPs from './MarkdownPs';
import Hcont from './Hcont';
import Text from './Text';
import TextP from './TextP';

import type { AboutFounder_founder } from '../__generated__/AboutFounder_founder';

type Props = $ReadOnly<{|
  founder: AboutFounder_founder,
  picAfter?: boolean,
  color: string,
|}>;

const avatarWidth = 960;
const avatarHeight = 960;

const AboutFounder = ({ founder, picAfter, color }: Props) => {
  const url = founder.avatar?.url;
  const src = url && `${url}?fit=max&w=${avatarWidth}&h=${avatarHeight}`;
  const descriptionHtml = founder.descriptionNode?.childMarkdownRemark?.html;

  return (
    <StyledSection color={color}>
      <StyledHcont picAfter={picAfter} noPadding>
        <StyledAreaImage>
          <img src={src} alt={founder.fullName} />
          <StyledDarkArea>
            <StyledQuote>
              <StyledText tag="div" color="white" size={32} italic>
                &quot;
                {founder.quote}
                &quot;
              </StyledText>
              <StyledTextName tag="div" color="white" size={21}>
                {founder.fullName}
              </StyledTextName>
            </StyledQuote>
          </StyledDarkArea>
        </StyledAreaImage>
        <StyledArea>
          <StyledAreaMiddle>
            <Text size={26} tag="h3" bold>
              {founder.fullName}
            </Text>
            <TextP>{founder.jobTitle}</TextP>
            {descriptionHtml && <StyledPs html={descriptionHtml} />}
          </StyledAreaMiddle>
        </StyledArea>
      </StyledHcont>
    </StyledSection>
  );
};

export default AboutFounder;

export const AboutFounderFragment = graphql`
  fragment AboutFounder_founder on DatoCmsFounder {
    id
    fullName
    jobTitle
    descriptionNode {
      childMarkdownRemark {
        html
      }
    }
    avatar {
      id
      url
    }
    quote
  }
`;
const StyledSection = styled('section')`
  background-color: ${p => p.color};
`;

const StyledHcont = styled(Hcont).attrs({ noPadding: true })`
  display: flex;
  flex-direction: ${p => (p.picAfter ? 'row' : 'row-reverse')};
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const StyledAreaImage = styled('div')`
  flex: 1;
  position: relative;
  > img {
    width: 100%;
  }
`;

const StyledDarkArea = styled('div')`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 1) 100%
  );
`;
const StyledQuote = styled('div')`
  position: absolute;
  height: 50%;
  bottom: 0px;
`;

const StyledArea = styled('div')`
  flex: 1;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledTextName = styled(Text)`
  text-align: center;
  margin-top: 10px;
`;

const StyledAreaMiddle = styled('div')`
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  width: 60%;
`;

const StyledPs = styled(MarkdownPs).attrs({ noMargin: true })`
  margin: ${rem(Dimen.spacing)} 0;
`;
