// @flow

export { newsSlug } from '../build-util';

export function capitalize(name: string): string {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function entries<T>(obj: { [key: string]: T }): Array<[string, T]> {
  return Object.keys(obj).map(k => [k, obj[k]]);
}

export function unwrapConnection<T>(
  connection: ?{ +edges: ?$ReadOnlyArray<?{ +node: ?T }> }
): T[] {
  const edges = connection?.edges;
  if (!edges) return [];

  return edges.map(e => e?.node).filter(Boolean);
}
