// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import config from '../config';
import { Dimen, Domain } from '../constants';
import AppPlatformIcon from './AppPlatformIcon';
import Button from './Button';
import Modal from './Modal';

type Props = $ReadOnly<{|
  label: ?string,
  link: ?string,
  className?: string,
|}>;

type State = {|
  modalVisible: boolean,
|};

export default class LinkedButton extends React.PureComponent<Props, State> {
  state = {
    modalVisible: false,
  };

  render() {
    const { label, link, className } = this.props;
    const { modalVisible } = this.state;

    if (!label || !link) return null;

    // TODO: Handle (app) modal
    if (link === '(app)') {
      return (
        <>
          <StyledShowOnDesktop>
            <Button
              label={label}
              href={`${config.PORTAL_ROOT || ''}/register`}
              className={className}
            />
          </StyledShowOnDesktop>
          <StyledShowOnMobile>
            <Button
              label={label}
              onClick={() => this.setState({ modalVisible: true })}
              className={className}
            />
          </StyledShowOnMobile>
          {modalVisible && (
            <Modal
              onDismiss={() => this.setState({ modalVisible: false })}
              title="Select Your Platform"
              padded
            >
              <StyledPlatforms>
                <a
                  href={Domain.appLinkIos}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppPlatformIcon platform="apple" large />
                  <span>iOS</span>
                </a>
                <a
                  href={Domain.appLinkAndroid}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppPlatformIcon platform="android" large />
                  <span>Android</span>
                </a>
              </StyledPlatforms>
            </Modal>
          )}
        </>
      );
    }

    return <Button label={label} to={link} className={className} />;
  }
}

const StyledShowOnDesktop = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

const StyledShowOnMobile = styled.div`
  @media (min-width: 501px) {
    display: none;
  }
`;

const StyledPlatforms = styled('div')`
  display: flex;
  align-items: center;

  a {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.25s;
    border-radius: 5px;
    padding: ${rem(Dimen.spacing / 2)} 0;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  span {
    margin-top: ${rem(15)};
  }
`;
