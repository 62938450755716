// @flow

const headerMobileTopHeight = 100;
const headerMobileBottomHeight = 80;

export default {
  headerHeight: 120,
  headerMobileTopHeight,
  headerMobileBottomHeight,
  headerMobileHeight: headerMobileTopHeight + headerMobileBottomHeight,

  spacing: 50,
};
